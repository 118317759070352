import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { CgLoadbarAlt } from 'react-icons/cg';
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
import customAxios from '../../utils/customAxios';

function SignIn() {
    const [submitBtn, setSubmitBtn] = useState('Sign In');
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [error, setError] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtn(<CgLoadbarAlt />)
        setSubmitBtnDisabled(true)

        await customAxios.post('account/signin', formData)
            .then(res => {

                const resObj = res.data
                if (resObj.code === 200) {
                    localStorage.setItem("token", resObj.data.token);
                    localStorage.setItem("firstName", resObj.data.account.firstName);
                    localStorage.setItem("email", resObj.data.account.email);
                    window.location.href = PATH_DASHBOARD.root;
                } else {
                    // console.log(resObj.msg)
                    setSubmitBtn('Sign Up')
                    setSubmitBtnDisabled(false)
                    setError(resObj.msg)
                }

            }).catch(err => {
                setError("Network Error")
                // console.log(err);
                setSubmitBtn('Sign Up');
                setSubmitBtnDisabled(false);
            });

    };



    return (
        <>
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="text-center pt-5 pb-5">
                            <Link
                                to=""
                                className="logo-link"
                            >
                                <div className="logo-wrap">
                                    <img
                                        className="logo-img logo-light"
                                        src="/assets/images/logo.png"
                                        alt=""
                                    />
                                    <img
                                        className="logo-img logo-dark"
                                        src="/assets/images/logo-dark.png"
                                        alt=""
                                    />
                                    <img
                                        className="logo-img logo-icon"
                                        src="/assets/images/logo-icon.png"
                                        alt=""
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
                                <div className="nk-block">
                                    <div className="nk-block-head text-center mb-4 pb-2">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Log in to Your Account</h1>
                                            <p className="small">
                                                Sign in to your account to customize your content
                                                generation settings and view your history.
                                            </p>
                                        </div>
                                    </div>
                                    <form noValidate onSubmit={handleSubmit}>
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="email">
                                                        Email Address
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className="form-control"
                                                            placeholder="Enter Email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="password">
                                                        Password
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            className="form-control"
                                                            placeholder="Enter Password"
                                                            value={formData.password}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <a
                                                    className="link small"
                                                    href="/"
                                                >Forgot password?</a
                                                >
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        type="submit"
                                                        id="submit-btn"
                                                        disabled={submitBtnDisabled}
                                                    >
                                                        {submitBtn}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        <p className="small">
                                            Don’t have an account? &nbsp;
                                            <Link to={PATH_AUTH.signUp}>Sign up</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer text-center">
                        &copy; 2023 All Rights Reserved to &nbsp;<a href="https://strano.io">Strano</a>
                    </div>
                </div>
            </div >
        </>
    );
}

export default SignIn;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Dashboard
import Dashboard from '../pages/Dashboard';
import NewPost from '../pages/NewPost';
import GeneratePost from '../pages/GeneratePost';
import EditPost from '../pages/EditPost';
import PricingPlan from '../pages/PricingPlan';
import Profile from '../pages/Profile';

import { PATH_AUTH, PATH_DASHBOARD } from './paths';
import Page404 from '../pages/404';
import SignUp from '../pages/auth/SignUp';
import SignIn from '../pages/auth/SignIn';

import AuthGuard from '../utils/AuthGuard';
import UnAuthGuard from '../utils/UnAuthGuard';

const token = localStorage.getItem('token');

function Routers() {
    return (
        <Router>
            <Routes>
                {/* Auth */}
                <Route path={PATH_AUTH.signUp}
                    element={<UnAuthGuard element={<SignUp />} isAuthenticated={token} />} />

                <Route path={PATH_AUTH.signIn}
                    element={<UnAuthGuard element={<SignIn />} isAuthenticated={token} />} />

                {/* Dashbaord */}
                <Route
                    path={PATH_DASHBOARD.root}
                    element={<AuthGuard element={<Dashboard />} isAuthenticated={token} />} />

                <Route
                    path={PATH_DASHBOARD.newPost}
                    element={<AuthGuard element={<NewPost />} isAuthenticated={token} />} />

                <Route
                    path={PATH_DASHBOARD.generatePost}
                    element={<AuthGuard element={<GeneratePost />} isAuthenticated={token} />} />

                <Route
                    path={PATH_DASHBOARD.editPost}
                    element={<AuthGuard element={<EditPost />} isAuthenticated={token} />} />

                <Route
                    path={PATH_DASHBOARD.pricingPlan}
                    element={<AuthGuard element={<PricingPlan />} isAuthenticated={token} />} />

                <Route
                    path={PATH_DASHBOARD.profile}
                    element={<AuthGuard element={<Profile />} isAuthenticated={token} />} />


                <Route path='*' element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default Routers;
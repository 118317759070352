
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import customAxios from '../utils/customAxios';
import Footer from '../components/Footer';

function Dashbaord() {
    const [posts, setPost] = useState([]);
    const [postLoading, setPostLoading] = useState(false);

    const checkAuth = async () => {
        try {
            // Make an authenticated API request to get user data
            await customAxios.get('/post/dashboard/list')
                .then(resObj => {
                    if (resObj.data.code === 200) {
                        setPost(resObj.data.data.sort().reverse().slice(0, 10))
                        setPostLoading(true)
                    } else {
                        console.log(resObj.data.data)
                    }
                }).catch(err => {
                    console.log(err.message)
                });
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <div className="nk-main">
            <Sidebar />
            <div className="nk-wrap">
                <Header />
                <div className="nk-content">
                    <div className="container-xl">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="nk-block-head nk-page-head">
                                    <div className="nk-block-head-between">
                                        <div className="nk-block-head-content">
                                            <h2 className="display-8">Welcome {localStorage.getItem('firstName')}!</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block">
                                    <div className="row g-gs">
                                        <div className="col-sm-6 col-xxl-3">
                                            <div className="card card-full bg-purple bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                        <div className="fs-6 text-light mb-0">Words Available</div><a
                                                            href="#"
                                                            className="link link-purple">See History</a>
                                                    </div>
                                                    <h5 className="fs-1">452 <small className="fs-3">words</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">1548</span>/2000 free words generated
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xxl-3">
                                            <div className="card card-full bg-blue bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                        <div className="fs-6 text-light mb-0">Drafts Available</div><a
                                                            href="#"
                                                            className="link link-blue">See All</a>
                                                    </div>
                                                    <h5 className="fs-1">3 <small className="fs-3">Drafts</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">7</span>/10 free drafts created</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xxl-3">
                                            <div className="card card-full bg-indigo bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                        <div className="fs-6 text-light mb-0">Documents Available</div><a
                                                            href="#"
                                                            className="link link-indigo">See All</a>
                                                    </div>
                                                    <h5 className="fs-1">6 <small className="fs-3">Documents</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">4</span>/10 free documents created</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xxl-3">
                                            <div className="card card-full bg-cyan bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                        <div className="fs-6 text-light mb-0">Tools Available</div><a
                                                            href="#"
                                                            className="link link-cyan">All Tools</a>
                                                    </div>
                                                    <h5 className="fs-1">12 <small className="fs-3">Tools</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">4</span>/16 free tools used to generate
                                                        content</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block-head">
                                    <div className="nk-block-head-between">
                                        <div className="nk-block-head-content">
                                            <h3 className="">Recent Post</h3>
                                        </div>
                                        <div className="nk-block-head-content">
                                            <a
                                                href="#"
                                                className="link">
                                                <span>See All</span>
                                                <em className="icon ni ni-chevron-right"></em>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block">
                                    <div className="card">
                                        <table className="table table-responsive table-middle mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="tb-col">
                                                        <h6 className="overline-title">S/N</h6>
                                                    </th>
                                                    <th className="tb-col">
                                                        <h6 className="overline-title">Title</h6>
                                                    </th>
                                                    <th className="tb-col tb-col-sm">
                                                        <h6 className="overline-title">Status</h6>
                                                    </th>
                                                    <th className="tb-col tb-col-md">
                                                        <h6 className="overline-title">Last Modified</h6>
                                                    </th>
                                                    <th className="tb-col">
                                                        <h6 className="overline-title"></h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {postLoading === false ?
                                                    <tr>
                                                        <td colSpan='5'>
                                                            <h4 className='text-center'>Loading...</h4>
                                                        </td>
                                                    </tr>
                                                    :
                                                    posts.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="tb-col tb-col-check">
                                                                {index + 1}
                                                            </td>
                                                            <td className="tb-col">
                                                                <div className="caption-text">
                                                                    {item.post_title !== undefined ?
                                                                        <a href={item.url} target='_black'>
                                                                            {item.post_title.slice(0, 40) + '...'}
                                                                        </a>
                                                                        :
                                                                        ''}
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                {item.status === 'complete' ?
                                                                    <div className="badge text-bg-success-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                                                        {item.status}
                                                                    </div>
                                                                    : item.status === 'process'
                                                                        ?
                                                                        <div className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                                                            {item.status + 'ing'}
                                                                        </div>
                                                                        :
                                                                        <div className="badge text-bg-success-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                                                            {item.status}
                                                                        </div>
                                                                }
                                                            </td>
                                                            <td className="tb-col tb-col-md">
                                                                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                    <span>
                                                                        <Moment format="YYYY/MM/DD hh:mm:ss" date={item.createdAt} />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-end">
                                                                <div className="dropdown">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-sm btn-icon btn-zoom me-n1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <em className="icon ni ni-more-h"></em>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <div className="dropdown-content">
                                                                                <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                                                    <li>
                                                                                        <a href="#l">
                                                                                            <em className="icon ni ni-eye"></em>
                                                                                            <span>View Document</span>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">
                                                                                            <em className="icon ni ni-edit"></em>
                                                                                            <span>Rename</span>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">
                                                                                            <em className="icon ni ni-trash"></em>
                                                                                            <span>Move to Trash</span>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    );
}

export default Dashbaord;

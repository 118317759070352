import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CgLoadbarAlt } from 'react-icons/cg';
import CopyToClipboard from 'react-copy-to-clipboard';

// import { PATH_AUTH } from '../routes/paths';
import { HOST_API, HOST_SHARE_URL } from '../config';
import { capitalizeFirstLetter, capitalizeFirstWordLetter, convertCreatedAtToDateAndTime, handleFindReplaceText, MarkdownToText } from '../utils/utils';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MarkdownBlock from '../utils/MarkdownBlock';
import customAxios from '../utils/customAxios';

function GeneratePost() {
    const [submitBtn, setSubmitBtn] = useState('Generate');
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [copied, setCopied] = useState(false);

    const [resLanguage, setResLanguage] = useState('');
    const [resTemplate, setResTemplate] = useState('');
    const [resResult, setResResult] = useState('');
    const [resId, setResId] = useState('');
    const [resWordCounter, setResWordCounter] = useState('');
    const [resDateAndTime, setResDateAndTime] = useState('');

    const [generatedPromptHistory, setGeneratedPromptHistory] = useState([]);

    const [formData, setFormData] = useState({
        template: 'affiliate-marketing',
        prompt: '',
        language: 'english'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtn(<CgLoadbarAlt />)
        setSubmitBtnDisabled(true)
        const token = localStorage.getItem("token");

        try {

            axios.post(HOST_API + "ai/prompt/create", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    console.log(res);
                    const resObj = res.data
                    if (resObj.code === 200) {
                        // console.log(resObj.data.token)
                        // localStorage.setItem("user", resObj.data.token)
                        setSuccess(resObj.msg)

                        setSubmitBtn('Generate')
                        setSubmitBtnDisabled(false)
                        // setMarkdownContent(resObj.data)
                        setResResult(resObj.data.result)
                        setResTemplate(resObj.data.template)
                        setResId(resObj.data.promptId)
                        setResWordCounter(resObj.data.words)
                        setResDateAndTime();
                        console.log(resObj.data)

                        // Reset the form
                        setFormData({
                            template: 'affiliate-marketing',
                            prompt: '',
                            language: 'english'
                        });
                        // navigate(PATH_AUTH.signIn, { replace: true })
                    } else {
                        setSubmitBtn('Generate')
                        setSubmitBtnDisabled(false)
                        setError(resObj.msg)
                    }

                }).catch(err => {
                    setError("Network Error")
                    console.log(err)
                    console.log('Network Error')
                    setSubmitBtn('Generate')
                    setSubmitBtnDisabled(false)
                })

        } catch (error) {
            // Handle any errors that may occur during the request
            console.error('Error:', error);
            setSubmitBtn('Generate')
            setSubmitBtnDisabled(false)
        }
    };

    const copyText = () => {
        setCopied(true);
        console.log('Copy to clipboard');
        setTimeout(() => setCopied(false), 1000); // Optional: Reset copied state after a delay
    };

    const promptList = async () => {
        try {
            // Make an authenticated API request to get user data
            await customAxios.get('ai/prompt/list')
                .then(resObj => {
                    // console.log(resObj.data.data)
                    if (resObj.data.code === 200) {
                        setGeneratedPromptHistory(resObj.data.data)
                    } else {
                        setGeneratedPromptHistory([])
                    }
                }).catch(err => {
                    console.log(err.message)
                    setGeneratedPromptHistory([])
                });
        } catch (error) {
            console.error('Authentication error:', error);
            setGeneratedPromptHistory([])
        }
    };

    useEffect(() => {

        promptList()

    }, []);

    return (
        <div className="nk-main">
            <Sidebar />
            <div className="nk-wrap">
                <Header />
                <div className="nk-content nk-content-empty">
                    <div className="nk-editor">
                        <div className="nk-editor-header">
                            <div className="nk-editor-title">
                                <h4 className="me-3 mb-0 line-clamp-1">2023-02-03 Untitled</h4>
                                <ul className="d-inline-flex align-item-center">
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-pen"></em></button></li>
                                    <li><button className="btn btn-sm btn-icon btn-zoom"><em
                                        className="icon ni ni-star"></em></button></li>
                                    <li className="d-xl-none me-n1">
                                        <div className="dropdown"><button className="btn btn-sm btn-icon btn-zoom"
                                            type="button" data-bs-toggle="dropdown"><em
                                                className="icon ni ni-menu-alt-r"></em></button>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                                <div className="dropdown-content">
                                                    <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-docs"></em><span>Docs</span></a>
                                                        </li>
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-text"></em><span>Text</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-editor-tools d-none d-xl-flex">
                                <ul className="d-inline-flex gap gx-3 gx-lg-4 pe-4 pe-lg-5">
                                    <li><span className="sub-text text-nowrap">Words <span
                                        className="text-dark">25</span></span></li>
                                    <li><span className="sub-text text-nowrap">Characters <span
                                        className="text-dark">84</span></span></li>
                                </ul>
                                <ul className="d-inline-flex gap gx-3">
                                    <li>
                                        <div className="dropdown"><button className="btn btn-md btn-light rounded-pill"
                                            type="button" data-bs-toggle="dropdown"><span>Export</span><em
                                                className="icon ni ni-chevron-down"></em></button>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                                <div className="dropdown-content">
                                                    <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-doc"></em><span>Docs</span></a>
                                                        </li>
                                                        <li><a href="#"><em
                                                            className="icon ni ni-file-text"></em><span>Text</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li><button className="btn btn-md btn-primary rounded-pill" type="button"> Save
                                    </button></li>
                                </ul>
                            </div>
                        </div>
                        <div className="nk-editor-main">
                            <div className="nk-editor-base">
                                <ul className="nav nav-tabs nav-sm nav-tabs-s1 px-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            type="button"
                                            data-bs-toggle="tab"
                                            data-bs-target="#AI-Writer"
                                            aria-selected="true"
                                            role="tab"
                                        >
                                            AI Writer
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            type="button"
                                            data-bs-toggle="tab"
                                            data-bs-target="#History"
                                            aria-selected="false"
                                            role="tab"
                                            tabIndex="-1"
                                        >
                                            History
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="AI-Writer" role="tabpanel">
                                        <div className="row gy-4 gx-4">
                                            <form noValidate onSubmit={handleSubmit} className="px-4 py-4">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Select Template</label>
                                                        <div className="form-control-wrap">
                                                            <select
                                                                className="form-select"
                                                                aria-label="select Template"
                                                                id="template"
                                                                name="template"
                                                                value={formData.template}
                                                                onChange={handleChange}
                                                                required
                                                            >
                                                                <option value="affiliate-marketing">Affiliate Marketing</option>
                                                                <option value="social-media-posts">Social Media Posts</option>
                                                                <option value="blog-post">Blog Post</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">What do you want to generate?</label>
                                                        <div className="form-control-wrap">
                                                            <textarea cols="30" rows="4" className="form-control"
                                                                id="prompt"
                                                                name="prompt"
                                                                value={formData.prompt}
                                                                onChange={handleChange}
                                                                minLength="10"
                                                                maxLength="500"
                                                                required
                                                            ></textarea>
                                                        </div>
                                                        <div className="form-note d-flex justify-content-end">
                                                            <span>0/500 Characters</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="PrimaryKeywords" className="form-label">
                                                            Select Language
                                                        </label>
                                                        <div className="form-control-wrap">
                                                            <select
                                                                className="form-select"
                                                                id="language"
                                                                name="language"
                                                                value={formData.language}
                                                                onChange={handleChange}
                                                                required
                                                            >
                                                                <option value="english">English</option>
                                                                <option value="spanish">Spanish</option>
                                                                <option value="russian">Russian</option>
                                                                <option value="german">German</option>
                                                                <option value="french">French</option>
                                                                <option value="japanese">Japanese</option>
                                                                <option value="portuguese">Portuguese</option>
                                                                <option value="turkish">Turkish</option>
                                                                <option value="italian">Italian</option>
                                                                <option value="persian">Persian</option>
                                                                <option value="polish">Polish</option>
                                                                <option value="chinese">Chinese</option>
                                                                <option value="indonesian">Indonesian</option>
                                                                <option value="korean">Korean</option>
                                                                <option value="arabic">Arabic</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div><br />
                                                <div className="col-6 text-end">
                                                    <div className="d-grid gap-2">
                                                        <button className="btn btn-primary w-100 "
                                                            disabled={submitBtnDisabled}
                                                        >
                                                            {submitBtn}
                                                        </button>
                                                    </div>
                                                </div><br />
                                                <div className="col-12">
                                                    {error && <div className="alert alert-danger" role="alert"><p>{capitalizeFirstLetter(error)}</p></div>}
                                                    {success &&
                                                        <>
                                                            <div className="card bg-primary-soft border-0 shadow-none">
                                                                <div className="p-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <div className="badge bg-primary bg-opacity-10 text-primary rounded-pill">
                                                                            <em className="icon ni ni-pen-fill"></em><span>{capitalizeFirstWordLetter(handleFindReplaceText(resTemplate, '-', ' '))}</span>
                                                                        </div>
                                                                        <ul className="d-flex align-items-center gap gx-1">
                                                                            {/* <li>
                                                                                <button className="btn btn-sm btn-icon btn-zoom" type="button">
                                                                                    <em className="icon ni ni-chevrons-left"></em>
                                                                                </button>
                                                                            </li> */}
                                                                            <li>
                                                                                <CopyToClipboard text={HOST_SHARE_URL + resId} onCopy={copyText}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="js-copy has-tooltip"
                                                                                    >
                                                                                        <em className="icon ni ni-share"></em><span>{copied ? <span>Copied</span> : 'Share Link'}</span>
                                                                                    </button>
                                                                                </CopyToClipboard>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <p className="text-dark" id="MediaPost01">
                                                                        <MarkdownBlock content={resResult} />
                                                                    </p>
                                                                    <div
                                                                        className="d-flex justify-content-between align-items-center fs-11px text-light"
                                                                    >
                                                                        <span>{resDateAndTime}</span><span>{resWordCounter} Words</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="alert alert-success" role="alert"><p>{capitalizeFirstLetter(success)}</p></div>
                                                        </>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="History" role="tabpanel">
                                        <div className="px-3 py-4">
                                            <div className="d-flex flex-wrap justify-content-between mb-3">
                                                <h6 className="mb-0 me-3">Generation History</h6>
                                                <div className="fs-11px">
                                                    <span className="text-dark">1763</span> words left.
                                                    <a href="#">Go Unlimited</a>
                                                </div>
                                            </div>
                                            <div className="row g-4" style={{ height: '100vh', overflowY: 'scroll', marginTop: '5px' }}>
                                                {Array.isArray(generatedPromptHistory) && (
                                                    <>
                                                        {generatedPromptHistory.map((item, index) => (
                                                            // <h1 key={index}>Hi</h1>
                                                            <div className="col-12" key={index}>
                                                                <div className="card bg-primary-soft border-0 shadow-none">
                                                                    <div className="p-3">
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-between mb-2"
                                                                        >
                                                                            <div
                                                                                className="badge bg-primary bg-opacity-10 text-primary rounded-pill"
                                                                            >
                                                                                <em className="icon ni ni-pen-fill"></em>
                                                                                <span>{capitalizeFirstWordLetter(handleFindReplaceText(item.template, '-', ' '))}</span>
                                                                            </div>
                                                                            <ul className="d-flex align-items-center gap gx-1">
                                                                                <li>
                                                                                    <button className="btn btn-sm btn-icon btn-zoom" type="button">
                                                                                        <em className="icon ni ni-chevrons-left"></em>
                                                                                    </button>
                                                                                </li>
                                                                                <li>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="js-copy has-tooltip"
                                                                                        data-clipboard-target="#SocialMediaPost01"
                                                                                    >
                                                                                        <em className="icon ni ni-copy"></em><span>Copy</span>
                                                                                    </button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <h6>{item.prompt}</h6><hr />
                                                                        <p className="text-dark" id="SocialMediaPost01">
                                                                            <MarkdownBlock content={item.result} />
                                                                        </p>
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center fs-11px text-light"
                                                                        >
                                                                            <span>{convertCreatedAtToDateAndTime(item.createdAt)}</span><span>{item.words && item.words + 'Words'} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="nk-editor-body">
                                <div className="wide-md h-100">
                                    <div className="js-editor nk-editor-style-clean nk-editor-full" data-menubar="false"
                                        id="editor-v1">
                                        <h1>Testing</h1>
                                        <ul>
                                            <li>sasa</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
}

export default GeneratePost;

import ReactMarkdown from 'react-markdown';

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeFirstWordLetter = (string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const handleFindReplaceText = (string, findWord, replaceWord) => {
    const regex = new RegExp(findWord, 'g');
    return string.replace(regex, replaceWord);
};

export const MarkdownToText = ({ markdownText }) => {
    return (
        <div>
            <ReactMarkdown>{markdownText}</ReactMarkdown>
        </div>
    );
};

export const convertCreatedAtToDateAndTime = (originalTime) => {
    const date = new Date(originalTime);
    const formattedTime = `${date.toLocaleString('en-us', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit' })}`;
    const meridiem = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${formattedTime} ${meridiem}`;
}

import React from 'react';
import { Link } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';

function Sidebar() {

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = PATH_AUTH.signIn;
    };

    const menuRouter = (path) => {
        // console.log(path);
        window.location.href = path;
    };

    const changeTopointer = {
        cursor: 'pointer'
    };

    return (
        <div className="nk-sidebar nk-sidebar-fixed" id="sidebar">
            <div className="nk-compact-toggle">
                <button
                    className="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3"
                >
                    <em className="icon off ni ni-chevron-left"></em>
                    <em className="icon on ni ni-chevron-right"></em>
                </button>
            </div>
            <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-sidebar-brand">
                    <span onClick={() => menuRouter(PATH_DASHBOARD.root)} style={changeTopointer} className="logo-link">
                        <div className="logo-wrap">
                            <img className="logo-img logo-light" src="assets/images/logo.png" alt="" />
                            <img className="logo-img logo-dark" src="/assets/images/logo-dark.png" alt="" />
                            <img className="logo-img logo-icon" src="/assets/images/logo-icon.png" alt="" />
                        </div>
                    </span>
                </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-body">
                <div className="nk-sidebar-content h-100" data-simplebar>
                    <div className="nk-sidebar-menu">
                        <ul className="nk-menu">
                            <li className="nk-menu-item">
                                <span onClick={() => menuRouter(PATH_DASHBOARD.root)} style={changeTopointer} className="nk-menu-link">
                                    <span className="nk-menu-icon">
                                        <em className="icon ni ni-dashboard-fill"></em>
                                    </span>
                                    <span className="nk-menu-text">Dashboard</span>
                                </span>
                            </li>
                            <li className="nk-menu-item">
                                <span onClick={() => menuRouter(PATH_DASHBOARD.generatePost)} style={changeTopointer} className="nk-menu-link">
                                    <span className="nk-menu-icon">
                                        <em className="icon ni ni-edit"></em>
                                    </span>
                                    <span className="nk-menu-text">Generate</span>
                                </span>
                            </li>
                            <li className="nk-menu-item">
                                <span className="nk-menu-link" onClick={() => menuRouter(PATH_DASHBOARD.pricingPlan)} style={changeTopointer}>
                                    <span className="nk-menu-icon">
                                        <em className="icon ni ni-sign-usdc"></em>
                                    </span>
                                    <span className="nk-menu-text">Pricing Plans</span>
                                </span>
                            </li>
                            <li className="nk-menu-item">
                                <span className="nk-menu-link" onClick={() => menuRouter(PATH_DASHBOARD.profile)} style={changeTopointer}>
                                    <span className="nk-menu-icon">
                                        <em className="icon ni ni-user"></em>
                                    </span>
                                    <span className="nk-menu-text">Profile</span>
                                </span>
                            </li>
                            <li className="nk-menu-item">
                                <span className="nk-menu-link" onClick={handleLogout} style={changeTopointer}>
                                    <span className="nk-menu-icon">
                                        <em className="icon ni ni-sign-usdc"></em>
                                    </span>
                                    <span className="nk-menu-text">Logout</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-footer">
                <div className="nk-sidebar-footer-extended pt-3">
                    <div className="border border-light rounded-3">
                        <div
                            className="px-3 py-2 bg-white border-bottom border-light rounded-top-3"
                        >
                            <div
                                className="d-flex flex-wrap align-items-center justify-content-between"
                            >
                                <h6 className="lead-text">Free Plan</h6>
                                <Link className="link link-primary" to="/pricing-plans">
                                    <em className="ni ni-spark-fill icon text-warning"></em>
                                    <span>Upgrade</span>
                                </Link>
                            </div>
                        </div>
                        <Link
                            className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                            to={PATH_DASHBOARD.profile}
                        >
                            <div className="media-group">
                                <div
                                    className="media media-sm media-middle media-circle text-bg-primary"
                                >
                                    <img src="/assets/images/user.png" />
                                </div>
                                <div className="media-text">
                                    <h6 className="fs-6 mb-0">{localStorage.getItem('firstName')}</h6>
                                    <span className="text-light fs-7">{localStorage.getItem('email')}</span>
                                </div>
                                <em className="icon ni ni-chevron-right ms-auto ps-1"></em>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Sidebar;
